import { useState,useEffect } from "react";
import "./home.css";
import { Link } from "react-router-dom"
import bg from "../../assets/background.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-fade";
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {EffectFade, Autoplay, Pagination, Navigation, EffectCoverflow}  from 'swiper/modules'; 
import firstImage from "../../assets/IMG_1064.png"
import shopMen from "../../assets/IMG_1068.JPG";
import shopWomen from "../../assets/IMG_1065.JPG";
import closet from "../../assets/IMG_1076.JPG"
import HomeHeader from "../header/homeHeader";

function Home () {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
    return(
      <>
      <HomeHeader/>
        <div className="home"
        >
          <div className="first-img">
                      <img src={firstImage}/>

          </div>
        <div className="home-swiper-container">

{/* <Swiper className="home-swiper"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1} 
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}   
          loop={true}
         autoplay={true}
          modules={[Autoplay,EffectCoverflow,Pagination,Navigation]}
>
  <SwiperSlide className="home-swiper-slide">
   Your No.1 thrift store 
  </SwiperSlide>
  <SwiperSlide className="home-swiper-slide">
    Enjoy Free Delivery around Ekiti State
  </SwiperSlide>
</Swiper> */}
</div>
{/* <div className="models-image">
<img className="models" src={male}/>
<img className="models" src={female}/>
<img className="models" src={bg}/>
</div> */}

<div className="on-models">
  <div className="lahni-text">
<h3>LAHNI THRIFTSTORE</h3>
              <p>style for cheap</p>
              </div>

<div className="categories-container" >
    
    <Link className="home-category home-links" to="/shirt">
        <div>
            <img src={shopMen}/>
        </div>
        <p className="gradient-text">MENSWEAR</p>
        </Link>

        <Link className="home-category home-links" to="/trouser">
        <div>
        <img src={shopWomen}/>
        </div>
        <p className="gradient-text">WOMENSWEAR</p>
        </Link>

        </div>
</div>

<div className="closet">
          <div className="closet-img">
                      <img src={closet}/>

          </div>
          <div className="on-closet">
          <p>We Style<br/>
YOU<br/>
for as low as<br/>
N3000</p>
<button>SHOP NOW</button>
</div>
        </div>
        </div>
        </>
    )
}


export default Home