import './App.css';
import React from 'react';
import { useState } from 'react';
// import modal from "./assets/modal.jpg"
import {Route, Routes} from 'react-router-dom';
import "./components/clothes/clothes.css"
import Footer from "./components/footer/footer"
import Home from './components/home/home';
import Pants from "./components/pants/pants"
import Shirt from "./components/shirt/shirt"
import AboutShirt from './components/clothes/aboutclothes';
import { CartProvider } from './components/context';
import Cart from './components/cart/cart';
import { ItemProvider } from './components/filecontext';
import Login from './components/forms/login';
import { ProductProvider } from './components/productContext';
import Signup from './components/forms/signup';

function App() {
  // State to track the visibility
  const [isVisible, setIsVisible] = useState(true);
  
  // Function to toggle the visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <CartProvider>
    <ItemProvider>
      <ProductProvider>
    <div className="App"> 
    {/* {isVisible ?  <div className="modals">
        <div className="home-modal-container">
        <button onClick={toggleVisibility}>X</button>
          <img className="home-modal" src={modal}/>
        </div>
        </div> : ""} */}
    <Routes>
      <Route element={<Home/>} path='/'/>
      <Route element={<Pants/>} path='/trouser'/>
      <Route element={<Shirt/>} path='/menshirt'/>  
      <Route element={<AboutShirt/>} path="/:id"/>
      <Route element={<Cart/>} path="/cart"/>
      <Route element={<Login/>} path="/login"/> 
      <Route element={<Signup/>} path='/signup'/>
    </Routes>
     <Footer/>
    </div>
    </ProductProvider>
    </ItemProvider>
    </CartProvider>
  );
}

export default App;
